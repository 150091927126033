import React, { useEffect, useState } from 'react';
import "../../App.css";
import Footer from '../Footer/Footer';
import { FaTrash } from "react-icons/fa";
import Header from '../Header/Header';
import { addDoc, collection, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ConfigPage() {
    const navigate = useNavigate();
    const [configData, setConfigData] = useState({
        email: "",
        json_key: "pdm-nasa-a3a442bd3181.json",
        project_id: "pdm-nasa",
        bucket_name: "pdm_data_examples",
        file_path: "pdm_swgr/output/trans_and_feature_eng_data/trans_feature_eng_data.csv",
        dataset_name: "",
        table_name: "",
        target_var: "scale",
        id_encode_var: "asset_id_encode",
        model_name: "DTC",
        param_grid: {
            criterion: "entropy",
            max_depth: 100,
            min_samples_leaf: 7,
            min_samples_split: 2,
            splitter: "best"
        },
        var_list: [
            "asset_id_encode",
            "cycle",
            "i_avg",
            "kva_tot",
            "kvar_tot",
            "kw_tot",
            "kwh",
            "pf_tot",
            "v_unbal",
            "scale"
        ],
        multi_equip: true,
        data_time: false,
        pickle_obj_save_path: "",
        func_records_save_path_bucket: "pdm_swgr/output/func_execution_records/classification_ml_models_records.csv",
        func_records_save_path_local: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfigData((prev) => ({ ...prev, [name]: value }));
    };

    const handleParamGridChange = (e) => {
        const { name, value } = e.target;
        setConfigData((prev) => ({
            ...prev,
            param_grid: {
                ...prev.param_grid,
                [name]: value
            }
        }));
    };

    const handleBooleanChange = (e) => {
        const { name, checked } = e.target;
        setConfigData((prev) => ({ ...prev, [name]: checked }));
    };

    const handleVarListChange = (e, index) => {
        const { value } = e.target;
        setConfigData((prev) => {
            const updatedVarList = [...prev.var_list];
            updatedVarList[index] = value;
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleAddVarListItem = () => {
        setConfigData((prev) => ({
            ...prev,
            var_list: [...prev.var_list, ""]
        }));
    };

    const handleRemoveVarListItem = (index) => {
        setConfigData((prev) => {
            const updatedVarList = prev.var_list.filter((_, i) => i !== index);
            return { ...prev, var_list: updatedVarList };
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const configCollection = collection(db, "mlmodel");
            const q = query(configCollection, where("email", "==", configData.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                await updateDoc(docRef, configData);
                toast.success("Configuration updated successfully!"); // Success toast
            } else {
                await addDoc(configCollection, configData);
                toast.success("Configuration saved successfully!"); // Success toast
            }

            navigate("/pipeline");
        } catch (error) {
            console.error("Error saving configuration: ", error);
            toast.error("Failed to save configuration."); // Error toast
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const configCollection = collection(db, "mlmodel");
                const q = query(configCollection, where("email", "==", currentUser.email));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docData = querySnapshot.docs[0].data();
                    setConfigData((prev) => ({ ...prev, ...docData }));
                    toast.success("Configuration fetched successfully!"); // Info toast
                } else {
                    setConfigData((prev) => ({ ...prev, email: currentUser.email }));
                    toast.info("No configuration found, using default values."); // Info toast for no config found
                }
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Header />
            <div className="config-form">
                <h3>Model Configuration</h3>
                <form onSubmit={handleSubmit}>
                    <label>
                        JSON Key:
                        <input
                            type="text"
                            name="json_key"
                            value={configData.json_key}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Project ID:
                        <input
                            type="text"
                            name="project_id"
                            value={configData.project_id}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Bucket Name:
                        <input
                            type="text"
                            name="bucket_name"
                            value={configData.bucket_name}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        File Path:
                        <input
                            type="text"
                            name="file_path"
                            value={configData.file_path}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Dataset Name:
                        <input
                            type="text"
                            name="dataset_name"
                            value={configData.dataset_name}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Table Name:
                        <input
                            type="text"
                            name="table_name"
                            value={configData.table_name}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Target Variable:
                        <input
                            type="text"
                            name="target_var"
                            value={configData.target_var}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        ID Encode Variable:
                        <input
                            type="text"
                            name="id_encode_var"
                            value={configData.id_encode_var}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Model Name:
                        <input
                            type="text"
                            name="model_name"
                            value={configData.model_name}
                            onChange={handleChange}
                        />
                    </label>

                    <div className='config-form-sub-box'>
                        <h3>Parameter Grid</h3>
                        <label>
                            Criterion:
                            <input
                                type="text"
                                name="criterion"
                                value={configData.param_grid.criterion}
                                onChange={handleParamGridChange}
                            />
                        </label>
                        <label>
                            Max Depth:
                            <input
                                type="number"
                                name="max_depth"
                                value={configData.param_grid.max_depth}
                                onChange={handleParamGridChange}
                            />
                        </label>
                        <label>
                            Min Samples Leaf:
                            <input
                                type="number"
                                name="min_samples_leaf"
                                value={configData.param_grid.min_samples_leaf}
                                onChange={handleParamGridChange}
                            />
                        </label>
                        <label>
                            Min Samples Split:
                            <input
                                type="number"
                                name="min_samples_split"
                                value={configData.param_grid.min_samples_split}
                                onChange={handleParamGridChange}
                            />
                        </label>
                        <label>
                            Splitter:
                            <input
                                type="text"
                                name="splitter"
                                value={configData.param_grid.splitter}
                                onChange={handleParamGridChange}
                            />
                        </label>
                    </div>

                    <div className='config-form-sub-box'>
                        <h3>Variable List</h3>
                        {configData.var_list.map((variable, index) => (
                            <div key={index} className="input-with-icon">
                                <input
                                    type="text"
                                    value={variable}
                                    onChange={(e) => handleVarListChange(e, index)}
                                />
                                <FaTrash className="trash-icon" onClick={(e) => handleRemoveVarListItem(index, e)} />
                            </div>
                        ))}
                        <button className="add-button" onClick={handleAddVarListItem}>Add Variable</button>
                    </div>

                    <div className='checkbox'>
                        <label>
                            Multi Equipment:
                            <input
                                type="checkbox"
                                name="multi_equip"
                                checked={configData.multi_equip}
                                onChange={handleBooleanChange}
                            />
                        </label>

                        <label>
                            Data Time:
                            <input
                                type="checkbox"
                                name="data_time"
                                checked={configData.data_time}
                                onChange={handleBooleanChange}
                            />
                        </label>
                    </div>

                    <label>
                        Pickle Object Save Path:
                        <input
                            type="text"
                            name="pickle_obj_save_path"
                            value={configData.pickle_obj_save_path}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Function Records Save Path (Bucket):
                        <input
                            type="text"
                            name="func_records_save_path_bucket"
                            value={configData.func_records_save_path_bucket}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Function Records Save Path (Local):
                        <input
                            type="text"
                            name="func_records_save_path_local"
                            value={configData.func_records_save_path_local}
                            onChange={handleChange}
                        />
                    </label>

                    <button type="submit" className="save-button">Save Configuration</button>
                    <button type="button" onClick={() => { navigate("/pipeline") }} className="cancel-button">Cancel</button>
                </form>
            </div>
            <Footer />
        </>
    );
}

export default ConfigPage;
