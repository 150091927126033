import React, { useState } from "react";
import styles from "./EditDatabaseDialog.module.css";

const EditDatabaseDialog = ({ datasetName, onSave, onClose }) => {
  const [name, setName] = useState(datasetName);

  const handleSave = () => {
    onSave(name);
    onClose();
  };

  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialog}>
        <h4>Edit Dataset Name</h4>
        <input
          className={styles.input}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button className={styles.button} onClick={handleSave}>
          Save
        </button>
        <button className={styles.button} onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditDatabaseDialog;
