import React, { useEffect, useState } from "react";
import { auth } from "../../firebase.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../Components/Footer/Footer.jsx";
import { useUser } from "../../UserContext.js";

const Sample = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  useEffect(() => {
    const aosScript = document.createElement("script");
    aosScript.src =
      "https://storage.googleapis.com/layout.kreatewebsites.com/atlas/js/aos.js";
    aosScript.async = true;
    document.body.appendChild(aosScript);

    aosScript.onload = () => {
      window.AOS.init({});
    };

    return () => {
      document.body.removeChild(aosScript);
    };
  }, []);
  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };

  const redirect = async (e) => {
    e.preventDefault();
    try {
      const userid = auth.currentUser;
      if (!userid) {
        navigate("/login");
        return null;
      }
      const email = userid.email;
      const name = userid.displayName;
      const nameParts = name.split(" ");
      const firstName = nameParts[0];
      const surname = nameParts.slice(1).join(" ");
      const response = await axios.get(
        "https://looker-url-5wz7dep6ya-uc.a.run.app/looker",
        {
          params: {
            email,
            name: firstName,
            surname: surname,
          },
        }
      );

      const output = response.data;
      const resultUrl = output.result;
      const fullUrl = `https://${resultUrl}`;
      console.log(resultUrl);

      window.open(fullUrl, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <div
        className="jumbotron jumbotron-fluid"
        id="banner"
        style={{
          backgroundImage:
            "url(https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/banner-bk.jpg)",
        }}
      >
        <div className="container text-center text-md-left">
          <header>
            <div className="row justify-content-between">
              <div className="col-2">
                <img
                  src="https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/logo.png"
                  alt="logo"
                />
              </div>
              <div className="col-6 align-self-center text-right">
                {user ? (
                  <a
                    onClick={handleSignOut}
                    className="text-white lead table-hover"
                  >
                    Logout
                  </a>
                ) : (
                  <a href="/login" className="text-white lead table-hover">
                    login
                  </a>
                )}
              </div>
            </div>
          </header>
          <h1
            data-aos="fade"
            data-aos-easing="linear"
            data-aos-duration="1000"
            data-aos-once="true"
            className="display-3 text-white font-weight-bold my-5"
          >
            AI Twin: See Future Of Your Assets Today
          </h1>
          <p
            data-aos="fade"
            data-aos-easing="linear"
            data-aos-duration="1000"
            data-aos-once="true"
            className="lead text-white my-4"
          >
            Optimize Assets with Machine Learning. AI amplifies the whispers
            from raw data. Build Data Product, guiding smarter operational,
            financial decisions.
          </p>
          <div>
            <a
              href="engine"
              data-aos="fade"
              data-aos-easing="linear"
              data-aos-duration="1000"
              data-aos-once="true"
              className="btn my-4 font-weight-bold atlas-cta cta-green mx-5"
            >
              Engines
            </a>
            <a
              href="looker"
              data-aos="fade"
              data-aos-easing="linear"
              data-aos-duration="1000"
              data-aos-once="true"
              className="btn my-4 font-weight-bold atlas-cta cta-green mx-5"
              onClick={redirect}
            >
              Looker Page
            </a>
            <a
              href="pipeline"
              data-aos="fade"
              data-aos-easing="linear"
              data-aos-duration="1000"
              data-aos-once="true"
              className="btn my-4 font-weight-bold atlas-cta cta-green mx-5"
            >
              Pipeline
            </a>
            <a
              href="nasa"
              data-aos="fade"
              data-aos-easing="linear"
              data-aos-duration="1000"
              data-aos-once="true"
              className="btn my-4 font-weight-bold atlas-cta cta-green mx-5"
            >
              Nasa
            </a>
          </div>
        </div>
      </div>

      <div className="container my-5 py-2">
        <h2 className="text-center font-weight-bold my-5">
          AI Driven Asset Management From the Ground Up
        </h2>
        <div className="row">
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
            className="col-md-4 text-center"
          >
            <img
              src="https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/smart-protect-2.jpg"
              alt="Combine Datasets"
              className="mx-auto"
            />
            <h4>Operations Planning</h4>
            <p>
              Improve maintenance plan and equipment reliability. Reduce
              unscheduled downtime.
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-once="true"
            className="col-md-4 text-center"
          >
            <img
              src="https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/smart-protect-1.jpg"
              alt="Create Websites and visualization"
              className="mx-auto"
            />
            <h4>Financial Planning</h4>
            <p>
              Build budget for replacement and future maintenance. Plan
              depreciation based on remaining useful life.
            </p>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-once="true"
            className="col-md-4 text-center"
          >
            <img
              src="https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/smart-protect-3.jpg"
              alt="Create Chatbots"
              className="mx-auto"
            />
            <h4>Warranty Planning</h4>
            <p>
              Unleash the power of data. Negotiate warranties with confidence
              using AI-powered predictions. AI reveals the true risk profile of
              your machines.
            </p>
          </div>
        </div>
      </div>

      {/* feature (skew background) */}
      <div className="jumbotron jumbotron-fluid feature" id="feature-first">
        <div className="container my-5">
          <div className="row justify-content-between text-center text-md-left">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-once="true"
              className="col-md-6"
            >
              <h2 className="font-weight-bold">Take a look inside</h2>
              <p className="my-4">
                Sensor data meets machine learning. The future of maintenance is
                here. Beyond thresholds, beyond limits. ML unlocks the full
                potential of predictive maintenance.
              </p>
              <a
                href="https://www.dataknobs.com/products/"
                className="btn my-4 font-weight-bold atlas-cta cta-blue"
              >
                Learn More
              </a>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-once="true"
              className="col-md-6 align-self-center"
            >
              <img
                src="https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/feature-1.png"
                alt="Take a look inside"
                className="mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </div>

      {/* price table */}
      <div className="container my-5 py-2" id="price-table">
        <h2 className="text-center font-weight-bold d-block mb-3">
          Predictive Maintenance for Data Centers and Factories
        </h2>
        <div className="row">
          <div
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
            className="col-md-4 text-center py-4 mt-5"
          >
            <h4 className="my-4">Smart Asset</h4>
            <p className="font-weight-bold">
              {" "}
              <span className="display-2 font-weight-bold">Asset</span>{" "}
            </p>
            <ul className="list-unstyled">
              <li>Your assets whisper, Twin listen</li>
            </ul>
            <a
              href="#"
              className="btn my-4 font-weight-bold atlas-cta cta-ghost"
            >
              See Approaches
            </a>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="200"
            data-aos-once="true"
            className="col-md-4 text-center py-4 mt-5 "
          >
            <h4 className="my-4">Higher level concepts from Asset Data</h4>
            <p className="font-weight-bold">
              <span className="display-2 font-weight-bold">Data Product</span>{" "}
            </p>
            <ul className="list-unstyled">
              <li>AI Built In, Not Bolted On:</li>
              <li>Asset Management That's Smarter From Day One.</li>
            </ul>
            <a
              href="#"
              className="btn my-4 font-weight-bold atlas-cta cta-green"
            >
              See Methods
            </a>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
            className="col-md-4 text-center py-4 mt-5"
          >
            <h4 className="my-4">Peak Performance</h4>
            <p className="font-weight-bold">
              <span className="display-2 font-weight-bold">Value</span>{" "}
            </p>
            <ul className="list-unstyled">
              <li>Predict Problems, Reduce Downtime, Protect Profits</li>
            </ul>
            <a
              href="#"
              className="btn my-4 font-weight-bold atlas-cta cta-ghost"
            >
              See Examples
            </a>
          </div>
        </div>
      </div>

      {/* client */}
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-4 col-md-2 py-2 align-self-center">
              <img
                src="https://storage.googleapis.com/create-website/ai-twin/1-pdm.png"
                className="mx-auto d-block"
                alt="Client Logo"
              />
            </div>
            <div className="col-sm-4 col-md-2 py-2 align-self-center">
              <img
                src="https://storage.googleapis.com/create-website/ai-twin/2-rul.png"
                className="mx-auto d-block"
                alt="Client Logo"
              />
            </div>
            <div className="col-sm-4 col-md-2 py-2 align-self-center">
              <img
                src="https://storage.googleapis.com/create-website/ai-twin/3-optimize.png"
                className="mx-auto d-block"
                alt="Client Logo"
              />
            </div>
            <div className="col-sm-4 col-md-2 py-2 align-self-center">
              <img
                src="https://storage.googleapis.com/create-website/ai-twin/5-monitoring.png"
                className="mx-auto d-block"
                alt="Client Logo"
              />
            </div>
            <div className="col-sm-4 col-md-2 py-2 align-self-center">
              <img
                src="https://storage.googleapis.com/create-website/ai-twin/4-anomaly.png"
                className="mx-auto d-block"
                alt="Client Logo"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Sample;
