import React from "react";

const Cylinder = ({
  width,
  height,
  x,
  y,
  fill,
  stroke,
  strokeWidth,
  onClick,
  label,
}) => {
  const ellipseRadiusX = width / 2;
  const ellipseRadiusY = width / 8;

  return (
    <svg width={width + x * 2} height={height + y * 2}>
      <ellipse
        cx={x + ellipseRadiusX}
        cy={y + ellipseRadiusY}
        rx={ellipseRadiusX}
        ry={ellipseRadiusY}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />

      <ellipse
        cx={x + ellipseRadiusX}
        cy={y + height - ellipseRadiusY}
        rx={ellipseRadiusX}
        ry={ellipseRadiusY}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x}
        y1={y + ellipseRadiusY}
        x2={x}
        y2={y + height - ellipseRadiusY}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x + width}
        y1={y + ellipseRadiusY}
        x2={x + width}
        y2={y + height - ellipseRadiusY}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />

      <text
        x={x + ellipseRadiusX}
        y={y + height / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="16"
        fill="#333"
        style={{ cursor: "pointer" }}
      >
        {label}
      </text>
    </svg>
  );
};

const DatabaseDiagram = ({ onClick, label }) => {
  return (
    <div onClick={onClick}>
      <Cylinder
        width={130}
        height={120}
        x={10}
        y={20}
        fill="#f9f9f9"
        stroke="#333"
        strokeWidth="2"
        label={label}
      />
    </div>
  );
};

export default DatabaseDiagram;
