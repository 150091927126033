export const NasaFormAttributes = [
  // data transform and feature engg
  {
    json_key: "pdm-nasa-a3a442bd3181.json",
    project_id: "pdm-nasa",
    bucket_name: "pdm_data_examples",
    file_path: "pdm_nasa_data/input/main_data/data_val.csv",
    dataset_name: "",
    table_name: "",
    var_list: "",
    target_var: "RemainingUsefulLife",
    id_encode_var: "id",
    save_data_path: "pdm_nasa_data/output/trans_and_feature_eng_data",
    func_records_save_path_bucket:
      "pdm_nasa_data/output/func_execution_records/data_tansf_feature_eng_records.csv",
    func_records_save_path_local: "",
  },
  // ML Models
  {
    json_key: "pdm-nasa-a3a442bd3181.json",
    project_id: "pdm-nasa",
    bucket_name: "pdm_data_examples",
    file_path: "pdm_nasa_data/input/main_data/nasa_data.csv",
    dataset_name: "",
    table_name: "",
    target_var: "RemainingUsefulLife",
    id_encode_var: "id",
    model_name: "DTR",
    param_grid: {
      criterion: "squared_error",
      max_depth: 11,
      min_samples_leaf: 60,
      min_samples_split: 2,
      splitter: "best",
    },
    var_list: "",
    pickle_obj_save_path: "pdm_nasa_data/output/pkl_files",
    func_records_save_path_bucket:
      "pdm_nasa_data/output/func_execution_records/regression_ml_models.csv",
    func_records_save_path_local: "",
    save_data_path: "pdm_nasa_data/output/regression_results_data",
  },
  // Model Evaluation
  {
    json_key: "pdm-nasa-a3a442bd3181.json",
    project_id: "pdm-nasa",
    bucket_name: "pdm_data_examples",
    csv_file_path: "pdm_nasa_data/input/main_data/nasa_data.csv",
    pkl_file_path: "pdm_nasa_data/output/pkl_files/DTR.pkl",
    dataset_name: "",
    table_name: "",
    target_var: "RemainingUsefulLife",
    id_encode_var: "id",
    var_list: "",
    model_name: "DTR",
    save_eval_data_path: "pdm_nasa_data/output/evalution_data",
    func_records_save_path_bucket:
      "pdm_nasa_data/output/func_execution_records/model_evaluation_records.csv",
    func_records_save_path_local: "",
  },
  //  Model Inference
  {
    json_key: "pdm-nasa-a3a442bd3181.json",
    project_id: "pdm-nasa",
    bucket_name: "pdm_data_examples",
    csv_file_path: "pdm_nasa_data/input/main_data/nasa_data_validation.csv",
    pkl_file_path: "pdm_nasa_data/output/pkl_files/DTR.pkl",
    dataset_name: "",
    table_name: "",
    id_encode_var: "id",
    var_list: "",
    model_name: "DTR",
    save_pred_data_path: "pdm_nasa_data/output/predicted_data",
    func_records_save_path_bucket:
      "pdm_nasa_data/output/func_execution_records/model_inference.csv",
    func_records_save_path_local: "",
  },
];
