import React, { useEffect, useState } from 'react';
import { FaTrash } from "react-icons/fa";
import "../../App.css"
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { addDoc, collection, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function NasaDataTransformConfig() {
    const navigate = useNavigate();
    const [configData, setConfigData] = useState({
        "email": "",
        "json_key": "pdm-nasa-a3a442bd3181.json",
        "project_id": "pdm-nasa",
        "bucket_name": "pdm_data_examples",
        "file_path": "pdm_nasa_data/input/main_data/data_val.csv",
        "dataset_name": "",
        "table_name": "",
        "var_list": [],
        "target_var": "RemainingUsefulLife",
        "id_encode_var": "id",
        "save_data_path": "pdm_nasa_data/output/trans_and_feature_eng_data",
        "func_records_save_path_bucket": "pdm_nasa_data/output/func_execution_records/data_tansf_feature_eng_records.csv",
        "func_records_save_path_local": ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfigData((prev) => ({ ...prev, [name]: value }));
    };

    const handleVarListChange = (e, index) => {
        const { value } = e.target;
        setConfigData((prev) => {
            const updatedVarList = [...prev.var_list];
            updatedVarList[index] = value;
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleAddVarListItem = (e) => {
        e.preventDefault();
        setConfigData((prev) => ({
            ...prev,
            var_list: [...prev.var_list, ""]
        }));
    };

    const handleRemoveVarListItem = (index) => {
        setConfigData((prev) => {
            const updatedVarList = prev.var_list.filter((_, i) => i !== index);
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const configCollection = collection(db, "NasaDataTransform");
            const q = query(configCollection, where("email", "==", configData.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                await updateDoc(docRef, configData);
                toast.success("Configuration updated successfully!");
            } else {
                await addDoc(configCollection, configData);
                toast.success("Configuration saved successfully!");
            }

            navigate("/nasa");
        } catch (error) {
            console.error("Error saving configuration: ", error);
            toast.error("Failed to save configuration.");
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const configCollection = collection(db, "NasaDataTransform");
                const q = query(configCollection, where("email", "==", currentUser.email));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docData = querySnapshot.docs[0].data();
                    console.log(docData);
                    setConfigData(docData);
                    toast.success("Fetched config successfully!");
                } else {
                    setConfigData((prev) => ({ ...prev, email: currentUser.email }));
                    toast.info("No config found for this block. Using Default Configurations");
                }
            }
        };

        fetchData();
    }, []);


    return (
        <>
            <Header />
            <div className="config-form">
                <h3>Data Transfrom and Feature Engineering</h3>
                <form onSubmit={handleSubmit}>
                    <label>
                        JSON Key:
                        <input type="text" name="json_key" value={configData.json_key} onChange={handleChange} />
                    </label>

                    <label>
                        Project ID:
                        <input type="text" name="project_id" value={configData.project_id} onChange={handleChange} />
                    </label>

                    <label>
                        Bucket Name:
                        <input type="text" name="bucket_name" value={configData.bucket_name} onChange={handleChange} />
                    </label>

                    <label>
                        Dataset Name:
                        <input type="text" name="dataset_name" value={configData.dataset_name} onChange={handleChange} />
                    </label>

                    <label>
                        File Path:
                        <input type="text" name="file_path" value={configData.file_path} onChange={handleChange} />
                    </label>

                    <label>
                        Table Name:
                        <input type="text" name="table_name" value={configData.table_name} onChange={handleChange} />
                    </label>

                    <label>
                        Target Variable:
                        <input type="text" name="target_var" value={configData.target_var} onChange={handleChange} />
                    </label>

                    <label>
                        ID Encode Variable:
                        <input type="text" name="id_encode_var" value={configData.id_encode_var} onChange={handleChange} />
                    </label>

                    <div className='config-form-sub-box'>
                        <h3>Variable List</h3>
                        {configData?.var_list?.map((variable, index) => (
                            <div key={index} className="input-with-icon">
                                <input
                                    type="text"
                                    value={variable}
                                    onChange={(e) => handleVarListChange(e, index)}
                                />
                                <FaTrash className="trash-icon" onClick={(e) => handleRemoveVarListItem(index, e)} />
                            </div>
                        ))}
                        <button className="add-button" onClick={(e) => handleAddVarListItem(e)}>Add Variable</button>
                    </div>

                    <label>
                        Save Data Path:
                        <input type="text" name="save_data_path" value={configData.save_data_path} onChange={handleChange} />
                    </label>
                    <label>
                        Execution Records Path (Bucket):
                        <input type="text" name="func_records_save_path_bucket" value={configData.func_records_save_path_bucket} onChange={handleChange} />
                    </label>
                    <label>
                        Execution Records Path (Local):
                        <input type="text" name="func_records_save_path_local" value={configData.func_records_save_path_local} onChange={handleChange} />
                    </label>

                    <button type="submit" className="save-button">Save Configuration</button>
                    <button type="button" onClick={() => { navigate("/nasa") }} className="cancel-button">Cancel</button>
                </form>
            </div>
            <Footer />
        </>
    );
}

export default NasaDataTransformConfig;
