import React, { useState, useRef, useEffect } from "react";
import styles from "./JsonEditor.module.css";

const JsonEditor = ({ initialData, onSave, onClose }) => {
  const [jsonData, setJsonData] = useState(
    JSON.stringify(initialData, null, 2)
  );
  const editorRef = useRef(null);

  const handleClickOutside = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSave = () => {
    try {
      const parsedData = JSON.parse(jsonData);
      onSave(parsedData);
      onClose();
    } catch (error) {
      alert("Invalid JSON format");
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal} ref={editorRef}>
        <textarea
          value={jsonData}
          onChange={(e) => setJsonData(e.target.value)}
          rows={15}
          cols={50}
          className={styles.textArea}
        />
        <div className={styles.buttonContainer}>
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default JsonEditor;
