import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { css } from "@emotion/react";
import { auth } from "../../firebase.js";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import styles from "./Transformer.module.css";
import Footer from "../../Components/Footer/Footer.jsx";
import { useUser } from "../../UserContext.js";

function Home() {
  const [data, setData] = useState([]);
  const [xAxisStart, setXAxisStart] = useState(1);
  const [xAxisEnd, setXAxisEnd] = useState(100);
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [chart, setChart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/fetch-file?bucketName=aitwin&fileName=transformer.txt`,
          {
            mode: "cors",
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }

        const { content } = await response.json();
        setFileContent(content);
      } catch (error) {
        console.error("Error fetching file:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFileContent();
  }, []);
  const parameterOptions = [
    "OLI",
    "OTI",
    "WTI",
    "ATI",
    "OLI_A",
    "OTI_T",
    "MOG_A",
    "VL1",
    "VL2",
    "VL3",
    "IL1",
    "IL2",
    "IL3",
    "VL12",
    "VL23",
    "VL31",
    "INUT",
  ];

  const handleRangeSubmit = () => {
    setChart(false);
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/data/transformer?start=${xAxisStart}&end=${xAxisEnd}&parameter=${selectedParameter}`
      )
      .then((response) => {
        setLoading(false);
        setData(response.data.parameter);
        setChart(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const chartData = {
    labels: data.map((item) => new Date(item.cycle).toLocaleString()) || [],
    datasets: [
      {
        label: selectedParameter
          ? `Parameter ${selectedParameter}`
          : "Selected Parameter",
        data: data.map((item) => item.value) || [],
        borderColor: "rgba(0, 0, 255, 1)",
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };

  const handleParameterChange = (e) => {
    setSelectedParameter(e.target.value);
  };

  const { setUser } = useUser();
  const navigate = useNavigate();
  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        navigate("/login");
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <>
      <div className={styles.navbar}>
        Transformer
        <button onClick={handleSignOut}>Logout</button>
      </div>
      <div className={styles.container}>
        <h6>{fileContent}</h6>
        <div className={styles.inputContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label htmlFor="startNumber" style={{ width: "120px" }}>
              Start Cycle:
            </label>
            <input
              type="number"
              id="startNumber"
              value={xAxisStart}
              onChange={(e) => setXAxisStart(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label htmlFor="endNumber" style={{ width: "120px" }}>
              End Cycle:
            </label>
            <input
              type="number"
              id="endNumber"
              value={xAxisEnd}
              onChange={(e) => setXAxisEnd(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label htmlFor="parameter" style={{ width: "120px" }}>
              Parameter:
            </label>
            <select
              id="parameter"
              value={selectedParameter}
              onChange={handleParameterChange}
              style={{ marginLeft: "25px" }}
            >
              <option value="Selected Parameter">Select Parameter</option>
              {parameterOptions.map((parameter) => (
                <option key={parameter} value={parameter}>
                  {parameter}
                </option>
              ))}
            </select>
          </div>
          <button className={styles.submitButton} onClick={handleRangeSubmit}>
            Submit
          </button>
        </div>
        {loading && (
          <div className={styles.chartContainer}>
            <BeatLoader
              css={override}
              size={15}
              color={"#123abc"}
              loading={loading}
            />
          </div>
        )}
        {chart && (
          <div className={styles.chartContainer}>
            <Line
              data={chartData}
              options={{ responsive: true, maintainAspectRatio: false }}
              className={styles.chartCanvas}
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Home;
