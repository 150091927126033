import React, { useRef, useEffect } from "react";
import styles from "./Dialog.module.css";

const Dialog = ({ content, onClose }) => {
  const dialogRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderTable = () => {
    if (!content || !content.columns || !content.data) return null;

    return (
      <table className={styles.dialogTable}>
        <thead>
          <tr>
            {content.columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {content.data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialogContent} ref={dialogRef}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        {renderTable()}
      </div>
    </div>
  );
};

export default Dialog;
