import React, { useEffect } from "react";
import { auth } from "./firebase.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/auth/login";
import Home from "./Pages/Home/Home.js";
import ReactPlot from "./Pages/ReactPlot/ReactPlot.js";
import Transformer from "./Pages/Transformer/Transformer.js";
import { useUser } from "./UserContext.js";
import Signup from "./Pages/auth/signUp/index.js";
import Heater from "./Pages/Heater/Heater.js";
import PipelineComponent from "./Components/DataTransformation/DataTransformationComponent.js";
import ConfigForm from "./Components/config/config.js";
import ConfigPage from "./Components/config/model.js";
import EvaluationConfigPage from "./Components/config/evaluation.js";
import EquipmentForm from "./Components/config/inference.js";
import NasaPipelineComponent from "./Components/DataTransformation/NasaDataTransformation.js";
import NasaDataTransformConfig from "./Components/NasaConfig/DataTransformConfig.js";
import NasaMLModelConfig from "./Components/NasaConfig/NasaMLModel.js";
import NasaModelEvaluationConfig from "./Components/NasaConfig/NasaModelEvaluation.js";
import NasaModelInferenceConfig from "./Components/NasaConfig/NasaModelInference.js";

const App = () => {
  const { user, setUser } = useUser();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/engine" element={!user ? <Login /> : <ReactPlot />} />
        <Route
          path="/transformer"
          element={!user ? <Login /> : <Transformer />}
        />
        <Route path="/heater" element={!user ? <Login /> : <Heater />} />
        <Route
          path="/pipeline"
          element={!user ? <Login /> : <PipelineComponent />}
        />
        <Route
          path="/nasa"
          element={!user ? <Login /> : <NasaPipelineComponent />}
        />
        <Route
          path="/pipeline/data-transform"
          element={!user ? <Login /> : <ConfigForm />}
        />
        <Route
          path="/pipeline/model"
          element={!user ? <Login /> : <ConfigPage />}
        />
        <Route
          path="/pipeline/model-evaluation"
          element={!user ? <Login /> : <EvaluationConfigPage />}
        />
        <Route
          path="/pipeline/model-inference"
          element={!user ? <Login /> : <EquipmentForm />}
        />
        <Route
          path="/nasa/data-transform"
          element={!user ? <Login /> : <NasaDataTransformConfig />}
        />
        <Route
          path="/nasa/model"
          element={!user ? <Login /> : <NasaMLModelConfig />}
        />
        <Route
          path="/nasa/model-evaluation"
          element={!user ? <Login /> : <NasaModelEvaluationConfig />}
        />
        <Route
          path="/nasa/model-inference"
          element={!user ? <Login /> : <NasaModelInferenceConfig />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
