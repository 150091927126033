import React, { useEffect, useState } from 'react';
import "../../App.css";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { FaTrash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { collection, addDoc, getDocs, query, where, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';

function EquipmentForm() {
    const navigate = useNavigate();
    const [equipmentData, setEquipmentData] = useState({
        email: "",
        json_key: "pdm-nasa-a3a442bd3181.json",
        project_id: "pdm-nasa",
        bucket_name: "pdm_data_examples",
        csv_file_path: "",
        pkl_file_path: "pdm_swgr/output/pkl_files/DTC.pkl",
        dataset_name: "ABB_equipments_data",
        table_name: "new_EUR-N-ABB-equipment-1",
        equip_id_var: "asset_id",
        equip_id: "EUR-N-ABB-equipment-1",
        category_mapping: {
            "EUR-N-ABB-equipment-1": 0,
            "EUR-N-ABB-equipment-2": 1,
            "EUR-S-ABB-equipment-1": 2,
            "EUR-S-ABB-equipment-2": 3,
            "USA-16-ABB-equipment-1": 4,
            "USA-16-ABB-equipment-2": 5,
            "USA-17-ABB-equipment-1": 6,
            "USA-17-ABB-equipment-2": 7
        },
        var_list: [
            "asset_id_encode",
            "cycle",
            "i_avg",
            "kva_tot",
            "kvar_tot",
            "kw_tot",
            "kwh",
            "pf_tot",
            "v_unbal"
        ],
        timeby: "D",
        df_start_date: "2012-03-14",
        equip_start_date: "2008-12-19",
        model_name: "DTC",
        save_pred_data_path: "pdm_swgr/output/predicted_data",
        save_trans_data_path: "",
        func_records_save_path_bucket: "pdm_swgr/output/func_execution_records/model_evaluation_records.csv",
        func_records_save_path_local: ""
    });

    useEffect(() => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            setEquipmentData((prev) => ({ ...prev, email: currentUser.email }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEquipmentData((prev) => ({ ...prev, [name]: value }));
    };

    const handleVarListChange = (e, index) => {
        const { value } = e.target;
        setEquipmentData((prev) => {
            const updatedVarList = [...prev.var_list];
            updatedVarList[index] = value;
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleAddVarListItem = (e) => {
        e.preventDefault();
        setEquipmentData((prev) => ({
            ...prev,
            var_list: [...prev.var_list, ""]
        }));
    };

    const handleRemoveVarListItem = (index, e) => {
        e.preventDefault();
        setEquipmentData((prev) => {
            const updatedVarList = prev.var_list.filter((_, i) => i !== index);
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleCategoryMappingChange = (e, key) => {
        const { value } = e.target;
        setEquipmentData((prev) => ({
            ...prev,
            category_mapping: {
                ...prev.category_mapping,
                [key]: parseInt(value, 10),
            },
        }));
    };
    const handleAddCategoryMapping = () => {
        setEquipmentData((prev) => {
            const newKey = `newCategory${Object.keys(prev.category_mapping).length + 1}`;
            const newCategoryMapping = {
                ...prev.category_mapping,
                [newKey]: 0,
            };
            return { ...prev, category_mapping: newCategoryMapping };
        });
    };

    const handleRemoveCategoryMapping = (index) => {
        setEquipmentData((prev) => {
            const updatedCategoryMapping = { ...prev.category_mapping };
            const keys = Object.keys(updatedCategoryMapping);
            const keyToRemove = keys[index];

            delete updatedCategoryMapping[keyToRemove];

            return { ...prev, category_mapping: updatedCategoryMapping };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const configCollection = collection(db, "modelInference");
            const q = query(configCollection, where("email", "==", equipmentData.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                await updateDoc(docRef, equipmentData);
                toast.success("Configuration updated successfully!"); // Success toast
            } else {
                await addDoc(configCollection, equipmentData);
                toast.success("Configuration saved successfully!"); // Success toast
            }

            navigate("/pipeline");
        } catch (error) {
            console.error("Error saving configuration: ", error);
            toast.error("Failed to save configuration."); // Error toast
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const configCollection = collection(db, "modelInference");
                const q = query(configCollection, where("email", "==", currentUser.email));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docData = querySnapshot.docs[0].data();
                    setEquipmentData((prev) => ({ ...prev, ...docData }));
                    toast.success("Fetched configuration successfully!"); // Info toast for data fetch
                } else {
                    setEquipmentData((prev) => ({ ...prev, email: currentUser.email }));
                    toast.info("No configuration found, using default values."); // Info toast for empty fetch
                }
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Header />
            <div className="config-form">
                <h3>Equipment Configuration</h3>
                <form onSubmit={handleSubmit}>
                    <label>JSON Key:
                        <input
                            type="text"
                            name="json_key"
                            value={equipmentData.json_key}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Project ID:
                        <input
                            type="text"
                            name="project_id"
                            value={equipmentData.project_id}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Bucket Name:
                        <input
                            type="text"
                            name="bucket_name"
                            value={equipmentData.bucket_name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>CSV File Path:
                        <input
                            type="text"
                            name="csv_file_path"
                            value={equipmentData.csv_file_path}
                            onChange={handleChange}
                        />
                    </label>
                    <label>PKL File Path:
                        <input
                            type="text"
                            name="pkl_file_path"
                            value={equipmentData.pkl_file_path}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Dataset Name:
                        <input
                            type="text"
                            name="dataset_name"
                            value={equipmentData.dataset_name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Table Name:
                        <input
                            type="text"
                            name="table_name"
                            value={equipmentData.table_name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Equipment ID Variable:
                        <input
                            type="text"
                            name="equip_id_var"
                            value={equipmentData.equip_id_var}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Equipment ID:
                        <input
                            type="text"
                            name="equip_id"
                            value={equipmentData.equip_id}
                            onChange={handleChange}
                        />
                    </label>

                    <div className='config-form-sub-box'>
                        <h3>Category Mapping</h3>
                        {Object.entries(equipmentData.category_mapping).map(([key, value], index) => (
                            <div key={index} className="category-mapping">
                                <label>
                                    Equipment Name:
                                    <input
                                        type="text"
                                        value={key}
                                        onChange={(e) => {
                                            const newKey = e.target.value;
                                            setEquipmentData((prev) => {
                                                const updatedCategoryMapping = { ...prev.category_mapping };
                                                delete updatedCategoryMapping[key];
                                                updatedCategoryMapping[newKey] = value;
                                                return { ...prev, category_mapping: updatedCategoryMapping };
                                            });
                                        }}
                                    />
                                </label>
                                <label>
                                    Category Number:
                                    <input
                                        type="number"
                                        value={value}
                                        onChange={(e) => handleCategoryMappingChange(e, key)}
                                    />
                                </label>
                                <button type="button" className="remove-button" onClick={() => handleRemoveCategoryMapping(index)}><FaTrash /></button>
                            </div>
                        ))}
                        <button type="button" className="add-button" onClick={handleAddCategoryMapping}>Add Category Mapping</button>
                    </div>

                    <div className='config-form-sub-box'>
                        <h3>Variable List</h3>
                        {equipmentData.var_list.map((variable, index) => (
                            <div key={index} className="input-with-icon">
                                <input
                                    type="text"
                                    value={variable}
                                    onChange={(e) => handleVarListChange(e, index)}
                                />
                                <FaTrash className="trash-icon" onClick={(e) => handleRemoveVarListItem(index, e)} />
                            </div>
                        ))}
                        <button className="add-button" onClick={(e) => handleAddVarListItem(e)}>Add Variable</button>
                    </div>

                    <label>Time By:
                        <input
                            type="text"
                            name="timeby"
                            value={equipmentData.timeby}
                            onChange={handleChange}
                        />
                    </label>
                    <label>DF Start Date:
                        <input
                            type="date"
                            name="df_start_date"
                            value={equipmentData.df_start_date}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Equip Start Date:
                        <input
                            type="date"
                            name="equip_start_date"
                            value={equipmentData.equip_start_date}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Model Name:
                        <input
                            type="text"
                            name="model_name"
                            value={equipmentData.model_name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Save Predicted Data Path:
                        <input
                            type="text"
                            name="save_pred_data_path"
                            value={equipmentData.save_pred_data_path}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Save Transformed Data Path:
                        <input
                            type="text"
                            name="save_trans_data_path"
                            value={equipmentData.save_trans_data_path}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Function Records Save Path (Bucket):
                        <input
                            type="text"
                            name="func_records_save_path_bucket"
                            value={equipmentData.func_records_save_path_bucket}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Function Records Save Path (Local):
                        <input
                            type="text"
                            name="func_records_save_path_local"
                            value={equipmentData.func_records_save_path_local}
                            onChange={handleChange}
                        />
                    </label>

                    <button type="submit" className="save-button">Save Equipment Data</button>
                    <button type="button" onClick={() => { navigate("/pipeline") }} className="cancel-button">Cancel</button>
                </form>
            </div>
            <Footer />
        </>
    );
}

export default EquipmentForm;
