export const formAttributes = [
  // data transformation 
  {
    "json_key": "pdm-nasa-a3a442bd3181.json",
    "project_id": "pdm-nasa",
    "bucket_name": "pdm_data_examples",
    "dataset_name": "ABB_equipments_data",
    "equip_id_var": "asset_id",
    "equipment_params_list": [
      {
        "file_path": "",
        "table_name": "EUR-N-ABB-equipment-1",
        "equip_id": "EUR-N-ABB-equipment-1",
        "equip_start_date": "2008-12-19",
        "df_start_date": "2012-03-14",
        "max_val": 6
      },
      {
        "file_path": "",
        "table_name": "EUR-N-ABB-equipment-2",
        "equip_id": "EUR-N-ABB-equipment-2",
        "equip_start_date": "2008-12-19",
        "df_start_date": "2012-03-14",
        "max_val": 6
      },
      {
        "file_path": "",
        "table_name": "EUR-S-ABB-equipment-1",
        "equip_id": "EUR-S-ABB-equipment-1",
        "equip_start_date": "2012-08-07",
        "df_start_date": "2012-07-08",
        "max_val": 6
      },
      {
        "file_path": "",
        "table_name": "EUR-S-ABB-equipment-2",
        "equip_id": "EUR-S-ABB-equipment-2",
        "equip_start_date": "2012-08-07",
        "df_start_date": "2012-07-08",
        "max_val": 6
      },
      {
        "file_path": "",
        "table_name": "USA-16-ABB-equipment-1",
        "equip_id": "USA-16-ABB-equipment-1",
        "equip_start_date": "2021-11-11",
        "df_start_date": "2021-11-12",
        "max_val": 6
      },
      {
        "file_path": "",
        "table_name": "USA-16-ABB-equipment-2",
        "equip_id": "USA-16-ABB-equipment-2",
        "equip_start_date": "2021-11-11",
        "df_start_date": "2021-11-12",
        "max_val": 6
      },
      {
        "file_path": "",
        "table_name": "USA-17-ABB-equipment-1",
        "equip_id": "USA-17-ABB-equipment-1",
        "equip_start_date": "2021-12-08",
        "df_start_date": "2021-09-12",
        "max_val": 24
      },
      {
        "file_path": "",
        "table_name": "USA-17-ABB-equipment-2",
        "equip_id": "USA-17-ABB-equipment-2",
        "equip_start_date": "2021-12-08",
        "df_start_date": "2021-09-12",
        "max_val": 24
      }
    ],
    "category_mapping": {
      "EUR-N-ABB-equipment-1": 0,
      "EUR-N-ABB-equipment-2": 1,
      "EUR-S-ABB-equipment-1": 2,
      "EUR-S-ABB-equipment-2": 3,
      "USA-16-ABB-equipment-1": 4,
      "USA-16-ABB-equipment-2": 5,
      "USA-17-ABB-equipment-1": 6,
      "USA-17-ABB-equipment-2": 7
    },
    "var_list": [
      "asset_id",
      "i_avg",
      "kva_tot",
      "kvar_tot",
      "kw_tot",
      "kwh",
      "pf_tot",
      "sagswellcount",
      "v_unbal"
    ],
    "scale_bin_var": "pf_tot",
    "timeby": "D",
    "bin_boundaries": {
      "pf_tot": [-75, -50, -25, 0, 25, 50, 75, 90, 95, 100]
    },
    "mean_std": [80, 20],
    "percentage": false,
    "save_data_path": "pdm_swgr/output/trans_and_feature_eng_data",
    "func_records_save_path_bucket": "pdm_swgr/output/func_execution_records/data_tansf_feature_eng_records.csv",
    "func_records_save_path_local": ""
  },
  // classification
  {
    "json_key": "pdm-nasa-a3a442bd3181.json",
    "project_id": "pdm-nasa",
    "bucket_name": "pdm_data_examples",
    "file_path": "pdm_swgr/output/trans_and_feature_eng_data/trans_feature_eng_data.csv", //
    "dataset_name": "",
    "table_name": "",
    "target_var": "scale",
    "id_encode_var": "asset_id_encode",
    "model_name": "DTC",
    "param_grid": {
      "criterion": "entropy",
      "max_depth": 100,
      "min_samples_leaf": 7,
      "min_samples_split": 2,
      "splitter": "best"
    },
    "var_list": [
      "asset_id_encode",
      "cycle",
      "i_avg",
      "kva_tot",
      "kvar_tot",
      "kw_tot",
      "kwh",
      "pf_tot",
      "v_unbal",
      "scale"
    ],
    "multi_equip": true,
    "data_time": false,
    "pickle_obj_save_path": "pdm_swgr/output/pkl_files",
    "func_records_save_path_bucket": "pdm_swgr/output/func_execution_records/classification_ml_models_records.csv",
    "func_records_save_path_local": "",
    "save_data_path": "pdm_swgr/output/classification_results_data"
  },
  // model eval
  {
    "json_key": "pdm-nasa-a3a442bd3181.json",
    "project_id": "pdm-nasa",
    "bucket_name": "pdm_data_examples",
    "csv_file_path": "pdm_swgr/output/trans_and_feature_eng_data/trans_feature_eng_data.csv",
    "pkl_file_path": "pdm_swgr/output/pkl_files/DTC.pkl",
    "dataset_name": "",
    "table_name": "",
    "target_var": "scale",
    "equip_id_var": "asset_id",
    "var_list": [
      "asset_id_encode",
      "cycle",
      "i_avg",
      "kva_tot",
      "kvar_tot",
      "kw_tot",
      "kwh",
      "pf_tot",
      "v_unbal",
      "scale"
    ],
    "model_name": "DTC",
    "save_eval_data_path": "pdm_swgr/output/evalution_data",
    "func_records_save_path_bucket": "pdm_swgr/output/func_execution_records/model_evaluation_records.csv",
    "func_records_save_path_local": ""
  },
  // inference
  {
    "json_key": "pdm-nasa-a3a442bd3181.json",
    "project_id": "pdm-nasa",
    "bucket_name": "pdm_data_examples",
    "csv_file_path": "",
    "pkl_file_path": "pdm_swgr/output/pkl_files/DTC.pkl",
    "dataset_name": "ABB_equipments_data",
    "table_name": "new_EUR-N-ABB-equipment-2",
    "equip_id_var": "asset_id",
    "equip_id": "EUR-N-ABB-equipment-2",
    "category_mapping": {
      "EUR-N-ABB-equipment-1": 0,
      "EUR-N-ABB-equipment-2": 1,
      "EUR-S-ABB-equipment-1": 2,
      "EUR-S-ABB-equipment-2": 3,
      "USA-16-ABB-equipment-1": 4,
      "USA-16-ABB-equipment-2": 5,
      "USA-17-ABB-equipment-1": 6,
      "USA-17-ABB-equipment-2": 7
    },
    "var_list": [
      "asset_id_encode",
      "cycle",
      "i_avg",
      "kva_tot",
      "kvar_tot",
      "kw_tot",
      "kwh",
      "pf_tot",
      "v_unbal"
    ],
    "timeby": "D",
    "df_start_date": "2012-03-14",
    "equip_start_date": "2008-12-19",
    "model_name": "DTC",
    "save_pred_data_path": "",
    "save_trans_data_path": "",
    "func_records_save_path_bucket": "pdm_swgr/output/func_execution_records/model_inference_records.csv",
    "func_records_save_path_local": "",
    "save_inference_data_path": "pdm_swgr/output/predicted_data"
  }
];
