import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./PipelineComponent.module.css";
import JsonEditor from "../JsonEditor/JsonEditor.js";
import Footer from "../Footer/Footer.jsx";
import Header from "../Header/Header.js";
import Dialog from "../Dialog/Dialog.js";
import { NasaFormAttributes } from "./NasaFormAttributes.js";
import DatabaseDiagram from "../DatabaseDiagram/DatabaseDiagram.js";
import EditDatabaseDialog from "../EditDatabaseDialog/EditDatabaseDialog.js";
import { useUser } from "../../UserContext.js";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../../firebase.js";
import { toast } from "react-toastify";
// import Blocks from "./datablocks.js";

const NasaPipelineComponent = () => {
  const { isAuthorized } = useUser();
  const navigate = useNavigate();
  const [apiStatus, setApiStatus] = useState(["", "", "", ""]);
  const [apiPrevStatus, setApPreviStatus] = useState(["", "", "", ""]);
  const [showEditor, setShowEditor] = useState(false);
  const [currentApiIndex, setCurrentApiIndex] = useState(null);
  const [dataTransformationState, setDataTransformationState] = useState("Run");
  const [classificationState, setClassificationState] = useState("Run");
  const [modelEvaluationState, setModelEvaluationState] = useState("Run");
  const [mlInferenceState, setMlInferenceState] = useState("Run");
  const [prevDataText, setPrevDataText] = useState("Fetch Previous Results");
  const [prevModelText, setPrevModelText] = useState("Fetch Previous Results");
  const [prevEvaluationText, setPrevEvaluationText] = useState(
    "Fetch Previous Results"
  );
  const [prevInferenceText, setPrevInferenceText] = useState(
    "Fetch Previous Results"
  );
  const [showDialog, setShowDialog] = useState(false);
  const [showPreviousResultsDialog, setShowPreviousResultsDialog] =
    useState(false);
  const [apiPreviousResponses, setApiPreviousResponses] = useState([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [prevText, setPrevText] = useState("Fetching");
  const [apiResponses, setApiResponses] = useState(["", "", "", ""]);
  const [dialogContent, setDialogContent] = useState("");
  const [previousResultsialogContent, setPreviousResultsDialogContent] =
    useState("");
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [formData, setFormData] = useState(NasaFormAttributes);

  const updateBoxStatus = (index, status, apiState) => {
    apiState((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = status;
      return newStatus;
    });
  };

  const handleEditDatabaseClick = () => {
    setShowEditDialog(true);
  };

  const handleSaveDatasetName = (newName) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[0] = {
        ...updatedFormData[0],
        dataset_name: newName,
      };
      return updatedFormData;
    });
  };

  const callApi = async (index) => {
    const apiCalls = [
      callDataTransformation,
      callClassification,
      callModelEvaluation,
      callMlInference,
    ];

    const collections = [
      "NasaDataTransform",
      "NasaMlmodel",
      "NasaModelEvaluation",
      "NasaModelInference",
    ];

    const apiCall = apiCalls[index];
    const collectionName = collections[index];

    if (!apiCall || !collectionName) return false;

    const user = auth.currentUser;
    const userEmail = user.email;

    const formDataInstance = new FormData();

    const q = query(
      collection(db, collectionName),
      where("email", "==", userEmail)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      Object.keys(userData).forEach((key) => {
        if (typeof userData[key] === "object") {
          formDataInstance.append(key, JSON.stringify(userData[key]));
        } else {
          formDataInstance.append(key, userData[key]);
        }
      });
    } else {
      Object.keys(formData[index]).forEach((key) => {
        if (typeof formData[index][key] === "object") {
          formDataInstance.append(key, JSON.stringify(formData[index][key]));
        } else {
          formDataInstance.append(key, formData[index][key]);
        }
      });
    }

    try {
      const response = await apiCall(formDataInstance);
      if (response.status === 200) {
        setApiResponses((prevResponses) => {
          const newResponses = [...prevResponses];
          newResponses[index] = response.data;
          return newResponses;
        });
      }
      return response.status === 200;
    } catch (error) {
      console.error(`Error in API ${index + 1}:`, error);
      return false;
    }
  };

  const callPreviousResponsesApi = async (index) => {
    const apiCalls = [
      (formData) =>
        axios.post(
          "https://pdm-nasa-data-539996738539.us-central1.run.app/load_data_from_bucket",
          formData
        ), // data trans
      (formData) =>
        axios.post(
          "https://pdm-nasa-data-539996738539.us-central1.run.app/load_data_from_bucket",
          formData
        ),
      (formData) =>
        axios.post(
          "https://pdm-nasa-data-539996738539.us-central1.run.app/load_data_from_bucket",
          formData
        ),
      (formData) =>
        axios.post(
          "https://pdm-nasa-data-539996738539.us-central1.run.app/load_data_from_bucket",
          formData
        ),
      (formData) =>
        axios.post(
          "https://pdm-nasa-data-539996738539.us-central1.run.app/load_data_from_bucket",
          formData
        ),
    ];

    const collections = [
      "NasaDataTransform",
      "NasaMlmodel",
      "NasaModelEvaluation",
      "NasaModelInference",
      null, // No collection needed for Matrix
    ];

    const fallbackFormData = [
      {
        json_key: NasaFormAttributes[0].json_key,
        project_id: NasaFormAttributes[0].project_id,
        bucket_name: NasaFormAttributes[0].bucket_name,
        file_path: `${NasaFormAttributes[0].save_data_path}/trans_feature_eng_data.csv`,
      }, // data transform
      {
        json_key: NasaFormAttributes[1].json_key,
        project_id: NasaFormAttributes[1].project_id,
        bucket_name: NasaFormAttributes[1].bucket_name,
        file_path: `${NasaFormAttributes[1].save_data_path}/regression_ml_model_result.csv`,
      }, // classification
      {
        json_key: NasaFormAttributes[2].json_key,
        project_id: NasaFormAttributes[2].project_id,
        bucket_name: NasaFormAttributes[2].bucket_name,
        file_path: `${NasaFormAttributes[2].save_eval_data_path}/model_eval_data.csv`,
      }, // evaluation
      {
        json_key: NasaFormAttributes[3].json_key,
        project_id: NasaFormAttributes[3].project_id,
        bucket_name: NasaFormAttributes[3].bucket_name,
        file_path: `${NasaFormAttributes[3].save_pred_data_path}/model_inference_data.csv`,
      }, // inference
      {
        json_key: NasaFormAttributes[2].json_key,
        project_id: NasaFormAttributes[2].project_id,
        bucket_name: NasaFormAttributes[2].bucket_name,
        file_path: `pdm_nasa_data/output/evalution_data/model_eval_matrix_results.csv`,
      }, // Matrix
    ];

    const apiCall = apiCalls[index];
    const collectionName = collections[index];

    if (!apiCall) {
      toast.error("Error: Invalid API index");
      return false;
    }

    const formDataInstance = new FormData();
    try {
      const user = auth.currentUser;
      const userEmail = user.email;

      if (collectionName) {
        const q = query(
          collection(db, collectionName),
          where("email", "==", userEmail)
        );
        const querySnapshot = await getDocs(q);

        const fallbackKeys = Object.keys(fallbackFormData[index]);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          fallbackKeys.forEach((key) => {
            if (key in userData) {
              if (typeof userData[key] === "object") {
                formDataInstance.append(key, JSON.stringify(userData[key]));
              } else {
                formDataInstance.append(key, userData[key]);
              }
            }
          });
        } else {
          fallbackKeys.forEach((key) => {
            if (typeof fallbackFormData[index][key] === "object") {
              formDataInstance.append(
                key,
                JSON.stringify(fallbackFormData[index][key])
              );
            } else {
              formDataInstance.append(key, fallbackFormData[index][key]);
            }
          });
        }
      } else {
        // Use fallback form data directly if no Firestore collection
        const fallbackKeys = Object.keys(fallbackFormData[index]);
        fallbackKeys.forEach((key) => {
          if (typeof fallbackFormData[index][key] === "object") {
            formDataInstance.append(
              key,
              JSON.stringify(fallbackFormData[index][key])
            );
          } else {
            formDataInstance.append(key, fallbackFormData[index][key]);
          }
        });
      }

      // Make API call with formDataInstance
      const response = await apiCall(formDataInstance);
      if (response.status === 200) {
        setApiPreviousResponses((prevResponses) => {
          const newResponses = [...prevResponses];
          newResponses[index] = response.data;
          updateBoxStatus(index, "Updated", setApPreviStatus);
          return newResponses;
        });
        return true;
      } else {
        toast.error("Error: API call failed");
        return false;
      }
    } catch (error) {
      console.error(`Error in API ${index + 1}:`, error);
      return false;
    }
  };

  const callDataTransformation = async (formDataInstance) => {
    return await axios.post(
      "https://pdm-nasa-data-hkdzghfnfvhbfqf9.eastus-01.azurewebsites.net/data_tansformation_and_feature_eng",
      formDataInstance
    );
  };

  const callClassification = async (formDataInstance) => {
    return await axios.post(
      "https://pdm-nasa-data-hkdzghfnfvhbfqf9.eastus-01.azurewebsites.net/regression_ml_models",
      formDataInstance
    );
  };

  const callModelEvaluation = async (formDataInstance) => {
    return await axios.post(
      "https://pdm-nasa-data-hkdzghfnfvhbfqf9.eastus-01.azurewebsites.net/model_evaluation",
      formDataInstance
    );
  };

  const callMlInference = async (formDataInstance) => {
    return await axios.post(
      "https://pdm-nasa-data-hkdzghfnfvhbfqf9.eastus-01.azurewebsites.net/model_inference",
      formDataInstance
    );
  };

  const handleClick = async () => {
    if (isAuthorized === false) {
      alert("You are not authorized to run this.");
      return;
    }
    updateBoxStatus(0, "", setApiStatus);
    updateBoxStatus(1, "", setApiStatus);
    updateBoxStatus(2, "", setApiStatus);

    setDataTransformationState("Running...");
    const firstSuccess = await callApi(0);
    if (firstSuccess) {
      updateBoxStatus(0, styles.green, setApiStatus);
      setDataTransformationState("Run");
      setClassificationState("Running...");
      const secondSuccess = await callApi(1);
      if (secondSuccess) {
        updateBoxStatus(1, styles.green, setApiStatus);
        setClassificationState("Run");

        setModelEvaluationState("Running...");
        const thirdSuccess = await callApi(2);
        if (thirdSuccess) {
          updateBoxStatus(2, styles.green, setApiStatus);
        } else {
          updateBoxStatus(2, styles.red, setApiStatus);
        }
        setModelEvaluationState("Run");
      } else {
        updateBoxStatus(1, styles.red, setApiStatus);
        setClassificationState("Run");
      }
    } else {
      updateBoxStatus(0, styles.red);
      setDataTransformationState("Run");
    }
  };

  const handleIndividualClick = async (index) => {
    if (isAuthorized === false) {
      alert("You are not authorized to run this.");
      return;
    }
    updateBoxStatus(index, "", setApiStatus);

    const result = await callApi(index);

    if (result) {
      updateBoxStatus(index, styles.green, setApiStatus);
    } else {
      updateBoxStatus(index, styles.red, setApiStatus);
    }
  };

  const openEditor = (index) => {
    setCurrentApiIndex(index);
    setShowEditor(true);
  };

  const openDialog = (index) => {
    setShowDialog(true);

    if (index === 2) {
      setDialogContent(apiResponses[index]?.result_df);
    } else if (index === 4) {
      const metricsData = apiResponses[2]?.result_metrics_df?.data?.[0];

      if (metricsData && typeof metricsData === "object") {
        const modifiedData = Object.values(metricsData).map((value) =>
          typeof value === "object" && value !== null ? null : value
        );

        console.log({
          ...apiResponses[2]?.result_metrics_df,
          data: [modifiedData],
        });
        setDialogContent({
          ...apiResponses[2]?.result_metrics_df,
          data: [modifiedData],
        });
      } else {
        setDialogContent(apiResponses[2]?.result_metrics_df);
      }
    } else {
      setDialogContent(apiResponses[index]);
    }
  };

  const openPreviousResultsDialog = (index) => {
    setShowPreviousResultsDialog(true);
    setPreviousResultsDialogContent(apiPreviousResponses[index]);
  };

  const handleSave = (updatedData) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[currentApiIndex] = updatedData;
      return newData;
    });
  };

  return (
    <>
      <Header />
      <div className={styles.pipelineContainer}>
        <h2>Nasa Data Pipeline</h2>
        <div className={styles.pipelineBoxContainer}>
          <DatabaseDiagram onClick={handleEditDatabaseClick} label="Dataset" />
          <span className={styles.arrow}>→</span>
          <div className={`${styles.box} ${apiStatus[0]}`}>
            Data Transform & Feature Engg.
            <div className={styles.buttonContainer}>
              <button onClick={() => navigate("/nasa/data-transform")}>
                Configure
              </button>
              <button
                onClick={async () => {
                  setDataTransformationState("Running...");
                  await handleIndividualClick(0);
                  setDataTransformationState("Run");
                }}
              >
                {dataTransformationState}
              </button>
              {apiStatus[0] !== "" && (
                <button onClick={() => openDialog(0)}>Result</button>
              )}
              {apiPrevStatus[0] === "" && (
                <button
                  onClick={async () => {
                    setPrevDataText("Fetching...");
                    await callPreviousResponsesApi(0);
                    setPrevDataText("Fetch Previous Results");
                  }}
                >
                  {prevDataText}
                </button>
              )}
              {apiPrevStatus[0] !== "" && (
                <button onClick={() => openPreviousResultsDialog(0)}>
                  Previous Result
                </button>
              )}
            </div>
          </div>
          <span className={styles.arrow}>→</span>

          {/* Classification ML Models Box */}
          <div className={`${styles.box} ${apiStatus[1]}`}>
            ML Models
            <div className={styles.buttonContainer}>
              <button onClick={() => navigate("/nasa/model")}>Configure</button>
              <button
                onClick={async () => {
                  setClassificationState("Running...");
                  await handleIndividualClick(1);
                  setClassificationState("Run");
                }}
              >
                {classificationState}
              </button>
              {apiStatus[1] !== "" && (
                <button onClick={() => openDialog(1)}>Result</button>
              )}
              {apiPrevStatus[1] === "" && (
                <button
                  onClick={async () => {
                    setPrevModelText("Fetching...");
                    await callPreviousResponsesApi(1);
                    setPrevModelText("Fetch Previous Results");
                  }}
                >
                  {prevModelText}
                </button>
              )}
              {apiPrevStatus[1] !== "" && (
                <button onClick={() => openPreviousResultsDialog(1)}>
                  Previous Result
                </button>
              )}
            </div>
          </div>
          <span className={styles.arrow}>→</span>
          <div className={`${styles.box} ${apiStatus[2]}`}>
            Model Evaluation
            <div className={styles.buttonContainer}>
              <button onClick={() => navigate("/nasa/model-evaluation")}>
                Configure
              </button>
              <button
                onClick={async () => {
                  setModelEvaluationState("Running...");
                  await handleIndividualClick(2);
                  setModelEvaluationState("Run");
                }}
              >
                {modelEvaluationState}
              </button>
              {apiStatus[2] !== "" && (
                <button onClick={() => openDialog(2)}>Result</button>
              )}
              {apiPrevStatus[2] === "" && (
                <button
                  onClick={async () => {
                    setPrevEvaluationText("Fetching...");
                    await callPreviousResponsesApi(2);
                    await callPreviousResponsesApi(4);
                    setPrevEvaluationText("Fetch Previous Results");
                  }}
                >
                  {prevEvaluationText}
                </button>
              )}
              {apiPrevStatus[2] !== "" && (
                <button onClick={() => openPreviousResultsDialog(2)}>
                  Previous Result
                </button>
              )}
            </div>
          </div>
          {apiPrevStatus[2] !== "" && (
            <>
              <span className={styles.arrow}>→</span>
              <DatabaseDiagram
                onClick={() => openPreviousResultsDialog(4)}
                label="Previous Metrics"
              />
            </>
          )}
          {apiResponses[2]?.result_metrics_df && (
            <>
              <span className={styles.arrow}>→</span>
              <DatabaseDiagram
                onClick={() => openDialog(4)}
                label="New Metrics"
              />
            </>
          )}
        </div>
        <button onClick={handleClick} className={styles.button}>
          Run All
        </button>

        <div className={styles.pipelineBoxContainer}>
          <DatabaseDiagram onClick={handleEditDatabaseClick} label="Dataset" />
          <span className={styles.arrow}>→</span>
          <div className={`${styles.box} ${apiStatus[3]}`}>
            ML Inference
            <div className={styles.buttonContainer}>
              <button onClick={() => navigate("/nasa/model-inference")}>
                Configure
              </button>
              <button
                onClick={async () => {
                  setMlInferenceState("Running...");
                  await handleIndividualClick(3);
                  setMlInferenceState("Run");
                }}
              >
                {mlInferenceState}
              </button>
              {apiStatus[3] !== "" && (
                <button onClick={() => openDialog(3)}>Result</button>
              )}
              {apiPrevStatus[3] === "" && (
                <button
                  onClick={async () => {
                    setPrevInferenceText("Fetching...");
                    await callPreviousResponsesApi(3);
                    setPrevInferenceText("Fetch Previous Results");
                  }}
                >
                  {prevInferenceText}
                </button>
              )}
              {apiPrevStatus[3] !== "" && (
                <button onClick={() => openPreviousResultsDialog(3)}>
                  Previous Result
                </button>
              )}
            </div>
          </div>
        </div>
        {showEditor && (
          <JsonEditor
            initialData={formData[currentApiIndex]}
            onSave={handleSave}
            onClose={() => setShowEditor(false)}
          />
        )}

        {showDialog && (
          <Dialog
            content={dialogContent}
            onClose={() => setShowDialog(false)}
          />
        )}

        {showPreviousResultsDialog && (
          <Dialog
            content={previousResultsialogContent}
            onClose={() => setShowPreviousResultsDialog(false)}
          />
        )}

        {showEditDialog && (
          <EditDatabaseDialog
            datasetName={formData[0].dataset_name}
            onSave={handleSaveDatasetName}
            onClose={() => setShowEditDialog(false)}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default NasaPipelineComponent;

// import styles from "./PipelineComponent.module.css";
// import Footer from "../Footer/Footer.jsx";
// import Header from "../Header/Header.js";
// import Blocks from "./NasaTransformation.js";
// import InferenceBlocks from "./NasaInference.js";
// import NasaHistoryDataTable from "../table/NasaTable.js";

// const NasaPipelineComponent = () => {
//   return (
//     <>
//       <Header />
//       <div className={styles.pipelineContainer}>
//         <h2>Nasa Data Pipeline</h2>
//       </div>
//       <Blocks />
//       <div className={styles.pipelineContainer}>
//         <h2>Nasa Inference Pipeline</h2>
//       </div>
//       <InferenceBlocks />
//       <NasaHistoryDataTable />
//       <Footer />
//     </>
//   );
// };

// export default NasaPipelineComponent;
