import React, { useEffect, useState } from 'react';
import "../../App.css";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { FaTrash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { collection, addDoc, getDocs, query, where, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';

function NasaModelInferenceConfig() {
    const navigate = useNavigate();
    const [equipmentData, setEquipmentData] = useState({
        email: "",
        "json_key": "pdm-nasa-a3a442bd3181.json",
        "project_id": "pdm-nasa",
        "bucket_name": "pdm_data_examples",
        "csv_file_path": "pdm_nasa_data/input/main_data/nasa_data_validation.csv",
        "pkl_file_path": "pdm_nasa_data/output/pkl_files/DTR.pkl",
        "dataset_name": "",
        "table_name": "",
        "id_encode_var": "id",
        "var_list": [],
        "model_name": "DTR",
        "save_pred_data_path": "pdm_nasa_data/output/predicted_data",
        "func_records_save_path_bucket": "pdm_nasa_data/output/func_execution_records/model_inference.csv",
        "func_records_save_path_local": ""
    });

    useEffect(() => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            setEquipmentData((prev) => ({ ...prev, email: currentUser.email }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEquipmentData((prev) => ({ ...prev, [name]: value }));
    };

    const handleVarListChange = (e, index) => {
        const { value } = e.target;
        setEquipmentData((prev) => {
            const updatedVarList = [...prev.var_list];
            updatedVarList[index] = value;
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleAddVarListItem = (e) => {
        e.preventDefault();
        setEquipmentData((prev) => ({
            ...prev,
            var_list: [...prev.var_list, ""]
        }));
    };

    const handleRemoveVarListItem = (index, e) => {
        e.preventDefault();
        setEquipmentData((prev) => {
            const updatedVarList = prev.var_list.filter((_, i) => i !== index);
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const configCollection = collection(db, "NasaModelInference");
            const q = query(configCollection, where("email", "==", equipmentData.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                await updateDoc(docRef, equipmentData);
                toast.success("Configuration updated successfully!"); // Success toast
            } else {
                await addDoc(configCollection, equipmentData);
                toast.success("Configuration saved successfully!"); // Success toast
            }

            navigate("/nasa");
        } catch (error) {
            console.error("Error saving configuration: ", error);
            toast.error("Failed to save configuration."); // Error toast
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const configCollection = collection(db, "NasaModelInference");
                const q = query(configCollection, where("email", "==", currentUser.email));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docData = querySnapshot.docs[0].data();
                    setEquipmentData((prev) => ({ ...prev, ...docData }));
                    toast.success("Fetched configuration successfully!"); // Info toast for data fetch
                } else {
                    setEquipmentData((prev) => ({ ...prev, email: currentUser.email }));
                    toast.info("No configuration found, using default values."); // Info toast for empty fetch
                }
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Header />
            <div className="config-form">
                <h3>Equipment Configuration</h3>
                <form onSubmit={handleSubmit}>
                    <label>JSON Key:
                        <input
                            type="text"
                            name="json_key"
                            value={equipmentData.json_key}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Project ID:
                        <input
                            type="text"
                            name="project_id"
                            value={equipmentData.project_id}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Bucket Name:
                        <input
                            type="text"
                            name="bucket_name"
                            value={equipmentData.bucket_name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>CSV File Path:
                        <input
                            type="text"
                            name="csv_file_path"
                            value={equipmentData.csv_file_path}
                            onChange={handleChange}
                        />
                    </label>
                    <label>PKL File Path:
                        <input
                            type="text"
                            name="pkl_file_path"
                            value={equipmentData.pkl_file_path}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Dataset Name:
                        <input
                            type="text"
                            name="dataset_name"
                            value={equipmentData.dataset_name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Table Name:
                        <input
                            type="text"
                            name="table_name"
                            value={equipmentData.table_name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>ID Encode Variable
                        <input
                            type="text"
                            name="id_encode_var"
                            value={equipmentData.id_encode_var}
                            onChange={handleChange}
                        />
                    </label>

                    <div className='config-form-sub-box'>
                        <h3>Variable List</h3>
                        {equipmentData.var_list.map((variable, index) => (
                            <div key={index} className="input-with-icon">
                                <input
                                    type="text"
                                    value={variable}
                                    onChange={(e) => handleVarListChange(e, index)}
                                />
                                <FaTrash className="trash-icon" onClick={(e) => handleRemoveVarListItem(index, e)} />
                            </div>
                        ))}
                        <button className="add-button" onClick={(e) => handleAddVarListItem(e)}>Add Variable</button>
                    </div>

                    <label>Model Name:
                        <input
                            type="text"
                            name="model_name"
                            value={equipmentData.model_name}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Save Predicted Data Path:
                        <input
                            type="text"
                            name="save_pred_data_path"
                            value={equipmentData.save_pred_data_path}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Function Records Save Path (Bucket):
                        <input
                            type="text"
                            name="func_records_save_path_bucket"
                            value={equipmentData.func_records_save_path_bucket}
                            onChange={handleChange}
                        />
                    </label>
                    <label>Function Records Save Path (Local):
                        <input
                            type="text"
                            name="func_records_save_path_local"
                            value={equipmentData.func_records_save_path_local}
                            onChange={handleChange}
                        />
                    </label>

                    <button type="submit" className="save-button">Save Equipment Data</button>
                    <button type="button" onClick={() => { navigate("/nasa") }} className="cancel-button">Cancel</button>
                </form>
            </div>
            <Footer />
        </>
    );
}

export default NasaModelInferenceConfig;
