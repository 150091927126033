import React, { useEffect } from "react";
import { useUser } from "../../UserContext";
import { auth } from "../../firebase.js";

const Header = () => {
  useEffect(() => {
    const aosScript = document.createElement("script");
    aosScript.src =
      "https://storage.googleapis.com/layout.kreatewebsites.com/atlas/js/aos.js";
    aosScript.async = true;
    document.body.appendChild(aosScript);

    aosScript.onload = () => {
      window.AOS.init({});
    };

    return () => {
      document.body.removeChild(aosScript);
    };
  }, []);
  const { user, setUser } = useUser();
  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };
  return (
    <div
      className="jumbotron jumbotron-fluid"
      id="banner"
      style={{
        backgroundImage:
          "url(https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/banner-bk.jpg)",
      }}
    >
      <div className="container text-center text-md-left">
        <header>
          <div className="row justify-content-between">
            <div className="col-2">
              <img
                src="https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/logo.png"
                alt="logo"
              />
            </div>
            <div className="col-6 align-self-center">
              {user ? (
                <>
                  <a
                    onClick={handleSignOut}
                    className="text-white lead table-hover m-5"
                  >
                    Logout
                  </a>
                  <a href="pipeline" className="text-white lead table-hover">
                    Pipeline
                  </a>
                  <a href="nasa" style={{ marginLeft: "40px" }} className="text-white lead table-hover">
                    Nasa
                  </a>
                </>
              ) : (
                <a href="/login" className="text-white lead table-hover">
                  Login
                </a>
              )}
            </div>
          </div>
        </header>
        <h1
          data-aos="fade"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-once="true"
          className="display-3 text-white font-weight-bold my-5"
        >
          AI Twin: See Future Of Your Assets Today
        </h1>
        <p
          data-aos="fade"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-once="true"
          className="lead text-white my-4"
        >
          Optimize Assets with Machine Learning. AI amplifies the whispers from
          raw data. Build Data Product, guiding smarter operational, financial
          decisions.
        </p>
      </div>
    </div>
  );
};

export default Header;
