import React, { useState } from "react";
import "./style.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, db } from "../../../firebase";
import InputControl from "../../../Components/inputController";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const Signup = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [errormsg, setErrormsg] = useState("");
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");

  const handlesubmission = async () => {
    if (!value.name || !value.email || !value.password) {
      setErrormsg("Fill in all fields");
      return;
    }

    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        value.email,
        value.password
      );
      const user = res.user;

      await updateProfile(user, {
        displayName: value.name,
      });

      // Create a user data object to store in Firestore
      const userData = {
        uid: user.uid, // Firebase UID
        name: value.name,
        email: value.email,
        credits: 0,
      };

      // If user_id exists in the URL, add it to the userData object
      if (user_id) {
        userData.user_id = user_id;
      }

      // Save the user details to the 'userdata' collection
      await addDoc(collection(db, "userdata"), userData);

      // Approve the user account if user_id exists
      if (user_id) {
        const response = await fetch(
          "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/approve-account",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          console.log("Signup approved");
        } else {
          console.log(
            "Failed to approve account. Error: " +
              (data.message || response.statusText)
          );
        }
      }

      // Clear the form
      setValue({
        name: "",
        email: "",
        password: "",
      });

      navigate("/");
    } catch (error) {
      setErrormsg(error.message);

      if (error.code === "auth/email-already-in-use") {
        setErrormsg("Email already in use. Please log in.");
        // Optionally, navigate to the login page
        // navigate("/login");
      }
    }
  };

  // login through google
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Reference to the 'userdata' collection
      const userdataRef = collection(db, "userdata");

      // Query to check if the user already exists in the 'userdata' collection by email
      const userQuery = query(userdataRef, where("email", "==", user.email));
      const querySnapshot = await getDocs(userQuery);

      // If an entry exists
      if (!querySnapshot.empty) {
        // Get the existing document
        const existingDoc = querySnapshot.docs[0];
        const existingData = existingDoc.data();

        // If user_id is already present, no further action needed
        if (existingData.user_id) {
          navigate("/");
          return;
        }

        // If user_id is missing, update the document with the user_id
        if (user.uid) {
          await updateDoc(doc(db, "userdata", existingDoc.id), {
            user_id: user.uid,
          });
        }
      } else {
        // Create a new user data object
        const userData = {
          uid: user.uid, // Firebase UID
          name: user.displayName || "Anonymous", // Use displayName or fallback to "Anonymous"
          email: user.email,
          credits: 0, // Initial credits or any other initial data you want
          user_id: user.uid, // Assign user_id if it exists
        };

        // Save the new user details to the 'userdata' collection
        await addDoc(userdataRef, userData);
      }

      // Redirect to the home page after successful sign-in
      navigate("/");
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        setErrormsg("Google sign-in popup was closed by the user.");
      } else {
        console.error("Error during Google sign-in:", error);
        setErrormsg("Failed to sign in with Google. Please try again.");
      }
    }
  };

  const signWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          // User tried to sign in with Facebook, but an account with the same email exists
          // using a different authentication provider.
          setErrormsg(
            "An account with this email already exists. Sign in using the same provider you used during sign-up."
          );
        } else {
          console.log(error);
        }
      });
  };

  return (
    <div className="signup_container">
      <div className="signup_innerBox">
        <div className="back">
          <button
            className="btn back_btn"
            onClick={() => {
              navigate("/");
            }}
          >
            <i className="fa fa-angle-double-left"></i> Back
          </button>
        </div>
        <h1 className="signup_heading">Sign Up</h1>
        <InputControl
          type="text"
          label="Name:"
          placeholder="Enter your Name"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, name: e.target.value }))
          }
          value={value.name}
        />
        <InputControl
          type="email"
          label="Email:"
          placeholder="Enter email"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, email: e.target.value }))
          }
          value={value.email}
        />
        <InputControl
          type="password"
          label="Password:"
          placeholder="Enter Password"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, password: e.target.value }))
          }
          value={value.password}
        />

        <div className="login_method">
          <button
            className="btn btn-block btn-social btn-facebook"
            onClick={signWithFacebook}
          >
            <i className="fab fa-facebook"></i>{" "}
            <span className="icon">Sign In with Facebook</span>
          </button>
          <button
            className="btn btn-block btn-social btn-google"
            onClick={signInWithGoogle}
          >
            <i className="fab fa-google"></i>{" "}
            <span className="icon">Sign In with Google</span>
          </button>
        </div>

        <div className="signup_footer">
          <b className="signup_error">{errormsg}</b>
          <button onClick={handlesubmission}>Sign Up</button>
          <p className="signup_p">
            Already have an acccount?{" "}
            <span>
              <Link to="/Login">Login</Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
