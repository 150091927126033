import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import Footer from "../../Components/Footer/Footer";
import { auth } from "../../firebase.js";
import { useUser } from "../../UserContext.js";
import { useNavigate } from "react-router-dom";
import styles from "./Heater.module.css";

const Heater = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [scatterData, setScatterData] = useState({ data: [] });
  const [fileContent, setFileContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/fetch-file?bucketName=aitwin&fileName=heater.txt`,
          {
            mode: "cors",
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }

        const { content } = await response.json();
        setFileContent(content);
      } catch (error) {
        console.error("Error fetching file:", error);
        setError("Failed to fetch file content");
      } finally {
        setLoading(false);
      }
    };

    fetchFileContent();
  }, []);

  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        navigate("/login");
        setUser(null);
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://data-store-api-v3gwgwf4xq-uc.a.run.app/scatterplot?device_id=B0005&&y_axis=Voltage_measured&&legend=id_cycle&&legend_value%20=%20True"
        );
        const data = await response.json();

        setScatterData(JSON.parse(data.plot));
      } catch (error) {
        console.error("Error fetching scatter plot data:", error);
        setError("Failed to fetch scatter plot data");
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className={styles.navbar}>
        Heater
        <button onClick={handleSignOut}>Logout</button>
      </div>
      <div className={styles.container}>
        {loading ? (
          <pre>Loading file content...</pre>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <h5>{fileContent}</h5>
        )}

        {loading ? (
          <p>Loading scatter plot data...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Plot data={scatterData.data} layout={{ title: "Scatter Plot" }} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Heater;
