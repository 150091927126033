import React, { useEffect, useState } from 'react';
import { FaTrash } from "react-icons/fa";
import "../../App.css"
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { addDoc, collection, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ConfigForm() {
    const navigate = useNavigate();
    const [configData, setConfigData] = useState({
        "email": "",
        "json_key": "pdm-nasa-a3a442bd3181.json",
        "project_id": "pdm-nasa",
        "bucket_name": "pdm_data_examples",
        "dataset_name": "ABB_equipments_data",
        "equip_id_var": "asset_id",
        "equipment_params_list": [
            {
                "file_path": "",
                "table_name": "EUR-N-ABB-equipment-1",
                "equip_id": "EUR-N-ABB-equipment-1",
                "equip_start_date": "2008-12-19",
                "df_start_date": "2012-03-14",
                "max_val": 6
            },
            {
                "file_path": "",
                "table_name": "EUR-N-ABB-equipment-2",
                "equip_id": "EUR-N-ABB-equipment-2",
                "equip_start_date": "2008-12-19",
                "df_start_date": "2012-03-12",
                "max_val": 6
            },
            {
                "file_path": "",
                "table_name": "USA-16-ABB-equipment-1",
                "equip_id": "USA-16-ABB-equipment-1",
                "equip_start_date": "2021-11-11",
                "df_start_date": "2021-11-12",
                "max_val": 6
            }
        ],
        "category_mapping": {
            "EUR-N-ABB-equipment-1": 0,
            "EUR-N-ABB-equipment-2": 1,
            "EUR-S-ABB-equipment-1": 2,
            "EUR-S-ABB-equipment-2": 3,
            "USA-16-ABB-equipment-1": 4,
            "USA-16-ABB-equipment-2": 5,
            "USA-17-ABB-equipment-1": 6,
            "USA-17-ABB-equipment-2": 7
        },
        "var_list": [
            "asset_id",
            "i_avg",
            "kva_tot",
            "kvar_tot",
            "kw_tot",
            "kwh",
            "pf_tot",
            "sagswellcount",
            "v_unbal"
        ],
        "scale_bin_var": "pf_tot",
        "timeby": "D",
        "bin_boundaries": {
            "pf_tot": [
                -75,
                -50,
                -25,
                0,
                25,
                50,
                75,
                90,
                95,
                100
            ]
        },
        "mean_std": [
            80,
            20
        ],
        "percentage": false,
        "save_data_path": "pdm_swgr/output/trans_and_feature_eng_data",
        "func_records_save_path_bucket": "pdm_swgr/output/func_execution_records/data_tansf_feature_eng_records.csv",
        "func_records_save_path_local": ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfigData((prev) => ({ ...prev, [name]: value }));
    };

    const handleEquipmentChange = (e, index) => {
        const { name, value } = e.target;
        setConfigData((prev) => {
            const updatedEquipmentParams = [...prev.equipment_params_list];
            updatedEquipmentParams[index] = {
                ...updatedEquipmentParams[index],
                [name]: value,
            };
            return { ...prev, equipment_params_list: updatedEquipmentParams };
        });
    };

    const handleRemoveCategoryMapping = (index) => {
        setConfigData((prev) => {
            const updatedCategoryMapping = { ...prev.category_mapping };
            const keys = Object.keys(updatedCategoryMapping);
            const keyToRemove = keys[index];

            delete updatedCategoryMapping[keyToRemove];

            return { ...prev, category_mapping: updatedCategoryMapping };
        });
    };


    const handleAddEquipmentParam = () => {
        setConfigData((prev) => ({
            ...prev,
            equipment_params_list: [
                ...prev.equipment_params_list,
                { file_path: '', table_name: '', equip_id: '', equip_start_date: '', df_start_date: '', max_val: 0 },
            ],
        }));
    };

    const handleCategoryMappingChange = (e, key) => {
        const { value } = e.target;
        setConfigData((prev) => ({
            ...prev,
            category_mapping: {
                ...prev.category_mapping,
                [key]: parseInt(value, 10),
            },
        }));
    };
    const handleAddCategoryMapping = () => {
        setConfigData((prev) => {
            const newKey = `newCategory${Object.keys(prev.category_mapping).length + 1}`;
            const newCategoryMapping = {
                ...prev.category_mapping,
                [newKey]: 0,
            };
            return { ...prev, category_mapping: newCategoryMapping };
        });
    };


    const handleVarListChange = (e, index) => {
        const { value } = e.target;
        setConfigData((prev) => {
            const updatedVarList = [...prev.var_list];
            updatedVarList[index] = value;
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleAddVarListItem = (e) => {
        e.preventDefault();
        setConfigData((prev) => ({
            ...prev,
            var_list: [...prev.var_list, ""]
        }));
    };

    const handleRemoveVarListItem = (index) => {
        setConfigData((prev) => {
            const updatedVarList = prev.var_list.filter((_, i) => i !== index);
            return { ...prev, var_list: updatedVarList };
        });
    };

    const handleBooleanChange = (e) => {
        const { name, checked } = e.target;
        setConfigData((prev) => ({ ...prev, [name]: checked }));
    };

    const handleArrayChange = (e, index, arrayName) => {
        const { value } = e.target;
        setConfigData((prev) => {
            const updatedArray = [...prev[arrayName]];
            updatedArray[index] = parseFloat(value);
            return { ...prev, [arrayName]: updatedArray };
        });
    };

    const handleRemoveEquipmentParam = (index) => {
        const updatedList = [...configData.equipment_params_list];
        updatedList.splice(index, 1);
        setConfigData({ ...configData, equipment_params_list: updatedList });
    };


    const handleBinBoundariesChange = (e, index) => {
        e.preventDefault();
        const { value } = e.target;
        setConfigData((prev) => {
            const updatedBinBoundaries = [...prev.bin_boundaries.pf_tot];
            updatedBinBoundaries[index] = parseFloat(value);
            return {
                ...prev,
                bin_boundaries: { ...prev.bin_boundaries, pf_tot: updatedBinBoundaries }
            };
        });
    };

    const handleAddBinBoundary = () => {
        setConfigData((prev) => ({
            ...prev,
            bin_boundaries: {
                ...prev.bin_boundaries,
                pf_tot: [...prev.bin_boundaries.pf_tot, 0],
            },
        }));
    };

    const handleRemoveBinBoundary = (index) => {
        setConfigData((prev) => {
            const updatedBinBoundaries = prev.bin_boundaries.pf_tot.filter((_, i) => i !== index);
            return {
                ...prev,
                bin_boundaries: { ...prev.bin_boundaries, pf_tot: updatedBinBoundaries }
            };
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const configCollection = collection(db, "dataTransform");
            const q = query(configCollection, where("email", "==", configData.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                await updateDoc(docRef, configData);
                toast.success("Configuration updated successfully!");
            } else {
                await addDoc(configCollection, configData);
                toast.success("Configuration saved successfully!");
            }

            navigate("/pipeline");
        } catch (error) {
            console.error("Error saving configuration: ", error);
            toast.error("Failed to save configuration.");
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const configCollection = collection(db, "dataTransform");
                const q = query(configCollection, where("email", "==", currentUser.email));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docData = querySnapshot.docs[0].data();
                    console.log(docData);
                    setConfigData(docData);
                    toast.success("Fetched config successfully!");
                } else {
                    setConfigData((prev) => ({ ...prev, email: currentUser.email }));
                    toast.info("No config found for this block. Using Default Configurations");
                }
            }
        };

        fetchData();
    }, []);


    return (
        <>
            <Header />
            <div className="config-form">
                <h3>Data Transfrom and Feature Engineering</h3>
                <form onSubmit={handleSubmit}>
                    <label>
                        JSON Key:
                        <input type="text" name="json_key" value={configData.json_key} onChange={handleChange} />
                    </label>

                    <label>
                        Project ID:
                        <input type="text" name="project_id" value={configData.project_id} onChange={handleChange} />
                    </label>

                    <label>
                        Bucket Name:
                        <input type="text" name="bucket_name" value={configData.bucket_name} onChange={handleChange} />
                    </label>

                    <label>
                        Dataset Name:
                        <input type="text" name="dataset_name" value={configData.dataset_name} onChange={handleChange} />
                    </label>

                    <label>
                        Equipment ID Variable:
                        <input type="text" name="equip_id_var" value={configData.equip_id_var} onChange={handleChange} />
                    </label>

                    <div className='config-form-sub-box'>
                        <h3>Equipment Parameters</h3>
                        {configData?.equipment_params_list?.map((param, index) => (
                            <div key={index} className="equipment-param">
                                <label>
                                    File Path:
                                    <input type="text" name="file_path" value={param.file_path} onChange={(e) => handleEquipmentChange(e, index)} />
                                </label>
                                <label>
                                    Table Name:
                                    <input type="text" name="table_name" value={param.table_name} onChange={(e) => handleEquipmentChange(e, index)} />
                                </label>
                                <label>
                                    Equipment ID:
                                    <input type="text" name="equip_id" value={param.equip_id} onChange={(e) => handleEquipmentChange(e, index)} />
                                </label>
                                <label>
                                    Equipment Start Date:
                                    <input type="date" name="equip_start_date" value={param.equip_start_date} onChange={(e) => handleEquipmentChange(e, index)} />
                                </label>
                                <label>
                                    Dataframe Start Date:
                                    <input type="date" name="df_start_date" value={param.df_start_date} onChange={(e) => handleEquipmentChange(e, index)} />
                                </label>
                                <label>
                                    Max Value:
                                    <input
                                        type="range"
                                        name="max_val"
                                        min="0"
                                        max="100"
                                        step="1"
                                        value={param.max_val}
                                        onChange={(e) => handleEquipmentChange(e, index)}
                                    />
                                    <span>{param.max_val}</span>
                                </label>
                                {/* Remove button */}
                                <button type="button" className="remove-button" onClick={() => handleRemoveEquipmentParam(index)}><FaTrash /></button>
                            </div>
                        ))}
                        <button type="button" className="add-button" onClick={handleAddEquipmentParam}>Add Equipment Parameter</button>
                    </div>


                    <div className='config-form-sub-box'>
                        <h3>Category Mapping</h3>
                        {Object.entries(configData?.category_mapping)?.map(([key, value], index) => (
                            <div key={index} className="category-mapping">
                                <label>
                                    Equipment Name:
                                    <input
                                        type="text"
                                        value={key}
                                        onChange={(e) => {
                                            const newKey = e.target.value;
                                            setConfigData((prev) => {
                                                const updatedCategoryMapping = { ...prev.category_mapping };
                                                delete updatedCategoryMapping[key];
                                                updatedCategoryMapping[newKey] = value;
                                                return { ...prev, category_mapping: updatedCategoryMapping };
                                            });
                                        }}
                                    />
                                </label>
                                <label>
                                    Category Number:
                                    <input
                                        type="number"
                                        value={value}
                                        onChange={(e) => handleCategoryMappingChange(e, key)}
                                    />
                                </label>
                                <button type="button" className="remove-button" onClick={() => handleRemoveCategoryMapping(index)}><FaTrash /></button>
                            </div>
                        ))}
                        <button type="button" className="add-button" onClick={handleAddCategoryMapping}>Add Category Mapping</button>
                    </div>

                    <div className='config-form-sub-box'>
                        <h3>Variable List</h3>
                        {configData?.var_list?.map((variable, index) => (
                            <div key={index} className="input-with-icon">
                                <input
                                    type="text"
                                    value={variable}
                                    onChange={(e) => handleVarListChange(e, index)}
                                />
                                <FaTrash className="trash-icon" onClick={(e) => handleRemoveVarListItem(index, e)} />
                            </div>
                        ))}
                        <button className="add-button" onClick={(e) => handleAddVarListItem(e)}>Add Variable</button>
                    </div>

                    <label>
                        Scale Bin Variable:
                        <input
                            type="text"
                            name="scale_bin_var"
                            value={configData.scale_bin_var}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Time By:
                        <input
                            type="text"
                            name="timeby"
                            value={configData.timeby}
                            onChange={handleChange}
                        />
                    </label>

                    <div className='config-form-sub-box'>
                        <h3>Bin Boundaries for pf_tot</h3>
                        {configData?.bin_boundaries?.pf_tot?.map((boundary, index) => (
                            <div key={index} className="input-with-icon">
                                <input
                                    type="text"
                                    value={boundary}
                                    onChange={(e) => handleBinBoundariesChange(e, index)}
                                />
                                <FaTrash className="trash-icon" onClick={(e) => handleRemoveBinBoundary(index, e)} />
                            </div>
                        ))}
                        <button type="button" className="add-button" onClick={handleAddBinBoundary}>Add Binary Boundaries</button>
                    </div>

                    {configData?.mean_std?.map((value, index) => (
                        <div key={index} className="mean-std">
                            <label>{index === 0 ? "Mean:" : "Standard Deviation:"} {value}</label>
                            <input
                                type="range"
                                value={value}
                                onChange={(e) => handleArrayChange(e, index, 'mean_std')}
                                name="mean_std"
                                min="-100"
                                max="100"
                                step="1"
                            />
                            <span></span>  {/* Display current slider value */}
                        </div>
                    ))}

                    <div className='checkbox'>
                        <label>
                            Percentage:
                            <input
                                type="checkbox"
                                name="percentage"
                                checked={configData.percentage}
                                onChange={handleBooleanChange}
                            />
                        </label>
                    </div>

                    <label>
                        Save Data Path:
                        <input type="text" name="save_data_path" value={configData.save_data_path} onChange={handleChange} />
                    </label>
                    <label>
                        Execution Records Path (Bucket):
                        <input type="text" name="func_records_save_path_bucket" value={configData.func_records_save_path_bucket} onChange={handleChange} />
                    </label>
                    <label>
                        Execution Records Path (Local):
                        <input type="text" name="func_records_save_path_local" value={configData.func_records_save_path_local} onChange={handleChange} />
                    </label>

                    <button type="submit" className="save-button">Save Configuration</button>
                    <button type="button" onClick={() => { navigate("/pipeline") }} className="cancel-button">Cancel</button>
                </form>
            </div>
            <Footer />
        </>
    );
}

export default ConfigForm;
